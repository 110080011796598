import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Text from "../components/text"
import Layout from "../components/layout"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import Time from "../utils/time"
import ReactMoment from 'react-moment'
import 'moment-timezone'
import moment from 'moment'
import styled from 'styled-components'  
import { EventDate, EventTitle } from '../styles/timeline'
import { 
  type_title, 
  type_body, 
  type_body_sm,
  type_label, 
  breakpoint, 
  typeBody, 
  typeBodyBold, 
  typeDate, 
  typeTitleLg, 
  grid, 
  colorTransition, 
  greyColor, 
  TextWrap, 
  type_general_lg 
} from '../styles/helpers'
import { 
  PageWrap, 
  PageNav, 
  PageNavInner,
  PageNavButton,
  PageNavItem, 
  PageWrapInner, 
  PageEntry, 
  PageEntryInner, 
  PageEntryTitle,
  PageEntryMark 
} from '../styles/page'
import SEO from "../components/seo"
import slugify from "../utils/slugify"
import sanitizeHtml from 'sanitize-html';

moment.tz.setDefault('Europe/Amsterdam')

export const query = graphql`
  query($slug: String) {
    sanityProject(slug: { current: { eq: $slug } }) {
      title
      id
      year
      date_ended
      date_started
      blurb
      tags_search
      tags_overview
      slug {
        current
      }
      entries {
        Title
        menu_title
        _rawContent
        Symbol
      }
      thumbnail {
        asset {
          fixed(width: 1200, height: 628) {
            src
            base64
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`

const ProjectTitle = styled.h1`
  width: 100%;
  text-align: center;
  ${type_title}
`

const ProjectDate = styled.h2`
  width: 100%;
  ${type_general_lg};
  font-weight: normal;
  text-align: center;
  // margin-bottom: 2rem;
  margin-bottom: 3.5rem;
`

const EventsWrap = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

const EventEntry = styled.section`
  ${type_label};
  width: 100%;
  width: 25%;
  padding: 0 .5rem 0 .5rem;
  // padding: 0 .5rem 0 0;
  text-align: center;
  margin-bottom: 3rem;
  & dt {
    white-space: pre;
  }
  // todo: add bps for tablet size etc.
  @media ${breakpoint.xs} {
    padding: 0;
    text-align: left;
    width: 100%;
    // width: 50%;
  }
`

export default ({ data, location, pageContext, ...props }) => {

  const [activeLink, setActiveLink] = useState('')
  const [navOpen, setNavOpen] = useState(false)
  const waypointRef = useRef()

  const intersectionOptions = {
    root: null,
    // can be -60%
    rootMargin: '0px 0px 0px 0px',
    threshold: 0
  }

  const firstNavItem = slugify(data.sanityProject.entries[0].Title) 
  
  useEffect(() => {
    props.mobile ? setNavOpen(false) : setNavOpen(true)
    setActiveLink(firstNavItem)
    const observer = new IntersectionObserver(observerCallback, intersectionOptions)
    // would be ideal to do this without timeout
    setTimeout(() => {
      const waypointTargets = document.querySelectorAll('#waypoint')
      if (waypointTargets.length) {
        waypointTargets.forEach((i, key) => {
          if (i) {
            observer.observe(i)
          }
        })
        // set observer bound here in local state?
      }
    }, 2000)
  },[])



  function observerCallback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // ideally would use refs instead
        const id = entry.target.getAttribute('data-id')
        setActiveLink(id)
      }
    })
  }
  
  const handleNavClick = (id) => {
    // ideally would use refs here instead
    const el = document.getElementById(id)
    const top = el.getBoundingClientRect().top
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  const toggleNav = () => {
    navOpen ? setNavOpen(false) : setNavOpen(true)
  }

  const relevantEvents = pageContext.events.filter((event, key) => {
    if (event.project !== null) {
      return event.project.slug.current === data.sanityProject.slug.current 
    }
  })

  const projectEvents = relevantEvents.map((x, key) => {
    return (
      <EventEntry>
        <ReactMoment format="DD.MM.YYYY, HH:mm">
          {x.date}
        </ReactMoment>
        <dt>{sanitizeHtml(x.detail)}</dt>
      </EventEntry>
    )
  })


  const entryLinks = data.sanityProject.entries.map((entry, key) => {
    const menuTitle = entry.menu_title !== null || entry.menu_title ? entry.menu_title : entry.Title
    return (
      <PageNavItem isProject={true} isActive={slugify(entry.Title) === activeLink ? true : false} onClick={(event) => handleNavClick(slugify(entry.Title))}>{ menuTitle }</PageNavItem>
    )
  })


  const entryContent = data.sanityProject.entries.map((entry, key) => {
    return (
      <PageEntry noPadding={key === 0 ? true : false} id={slugify(entry.Title)} fullWidthImages={true}>
        <PageEntryTitle>{entry.Title}</PageEntryTitle>
        <PageEntryInner id='waypoint' data-id={slugify(entry.Title)}>
          <PortableText blocks={entry._rawContent} serializers={serializer} />
        </PageEntryInner>
        {/* <PageEntryMark>
          { entry.Symbol }
        </PageEntryMark> */}
      </PageEntry>
    )
  })


  const seoDescription = data.sanityProject.blurb !== null ? data.sanityProject.blurb : data.sanityProject.title
  const seoImage = data.sanityProject.thumbnail !== null ? data.sanityProject.thumbnail.asset.fixed.src : false
  const seoComponent = !seoImage ? <SEO title={data.sanityProject.title} description={seoDescription} /> : <SEO title={data.sanityProject.title} description={seoDescription} image={seoImage} />

  const navInner = navOpen ?
    <PageNavInner>
      <ul>
        {entryLinks}
        {/* events turned off for now */}
        {/* <PageNavItem isProject={true} isActive={activeLink === 'events' ? true : false} onClick={(event) => handleNavClick('events')}>Events</PageNavItem> */}
        {/* back button turned off for now */}
        {/* <PageNavItem isProject={true}><Link to='/projects'>← Index</Link></PageNavItem> */}
      </ul>
    </PageNavInner>
    :
    false

    const navButton = props.mobile ? <PageNavButton navOpen={navOpen} onClick={(event) => toggleNav()}>{!navOpen ? "..." : "+"}</PageNavButton> : false

  return (
    <>
    {seoComponent}
      <PageWrap id='project'>
        <PageNav sticky={true} navOpen={navOpen}>
          {navButton}
          {navInner}
        </PageNav>
        <PageWrapInner columnWidth={8}>
          <ProjectTitle>
            { data.sanityProject.title }
          </ProjectTitle>
          <ProjectDate>
            { data.sanityProject.year }
          </ProjectDate>
          { entryContent }
          {/* turned off for now */}
          {/* <PageEntry id={slugify('events')}>
            <PageEntryTitle alignLeft={props.mobile}>Events</PageEntryTitle>
            <EventsWrap id='waypoint' data-id={slugify('events')}>
              { projectEvents } 
            </EventsWrap>
          </PageEntry> */}
        </PageWrapInner>
      </PageWrap>
    </>
  )
}
